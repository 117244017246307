import React, { Component } from 'react'
import $ from "jquery";

export default class Modal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cur_content: '',
            cur_name: '',
        }
    }
    componentDidMount() {
        $('#win-modal').hide();
    }
    openModal(event) {
        $('#win-modal').slideToggle('slow');
    }
    closeModal(event) {
        $('#win-modal').slideUp('slow', () => this.setState({ cur_content: this.props.content }));

    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.content !== this.props.content) {
            let name = this.props.content.type.WrappedComponent.name
            if (name !== this.state.cur_name) {
                this.closeModal()
                this.setState({ cur_name: name })
            }
            this.openModal()
        }
    }
    render() {
        return (
            <section id="win-modal">
                <span id="ferme-modal" onClick={(event) => this.closeModal(event)}>x</span>
                {
                    this.state.isLoading ?
                        'Loading...'
                        :
                        this.state.cur_content

                }
            </section>

        );
    }
}