import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';

class Help extends Component {
    render() {
        const { t } = this.props;

        return (
            <div id="modal-help" className="modal-content">
                <h2>{t('site.title')}</h2>
                <div
                    className="help-content"
                    dangerouslySetInnerHTML={{
                        __html: t('help.content')
                    }}
                ></div>
                <div
                    className="help-example"
                    dangerouslySetInnerHTML={{
                        __html: t('help.example')
                    }}
                ></div>
            </div>
        );

    }
}

export default withTranslation()(Help)