import React, { Component } from 'react'
import { FicheInput, FicheSelector } from './FicheInput'
import { UsersInput, UsersSelector } from './UsersInput'
import { KeywordsInput, KeywordsSelector } from './KeywordsInput'
import apis from '../../api/api'
import users from '../../api/user'

export default class Tab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [],
            isLoading: false,
            cur_item: [],
            selected: "",
        }
    }
    componentDidMount = async () => {
        this.getAllItems()
    }
    openTab(evt, name) {
        this.setState({ 'selected': name })
    }
    getAllItems = async () => {
        this.setState({ isLoading: true })
        if (this.props.type === 'fiches') {
            await apis.getAllFiches().then(items => {
                items = items.data.data
                this.setState({
                    items: items,
                    isLoading: false,
                })
                this.setState({
                    cur_item: this.state.items[0]
                })
            })
        }
        else if (this.props.type === 'users') {
            await users.getAllUsers().then(items => {
                items = items.data
                this.setState({
                    items: items,
                    isLoading: false,
                })
                this.setState({
                    cur_item: this.state.items[0]
                })
            })
        }
        else if (this.props.type === 'keywords') {
            await apis.getAllKeywords().then(items => {
                items = items.data.data
                this.setState({
                    items: items,
                    isLoading: false,
                })
                this.setState({
                    cur_item: this.state.items[0]
                })
            })
        }
    }
    queryItem = async (e) => {
        e.persist()
        let id = e.target.value
        await apis.getFicheById(id).then(fiche => {
            this.setState({ cur_item: fiche.data.data })
        })
    }
    render = () => {
        return (
            <div className="tabs">

                <div className="tab">
                    <button className={"subtablinks " + (this.state.selected === 'insert' && 'active')} onClick={(event) => this.openTab(event, 'insert')}>Ajouter</button>
                    <button className={"subtablinks " + (this.state.selected === 'update' && 'active')} onClick={(event) => this.openTab(event, 'update')}>Modifier</button>
                    <button className={"subtablinks " + (this.state.selected === 'delete' && 'active')} onClick={(event) => this.openTab(event, 'delete')}>Supprimer</button>
                </div>

                {this.state.selected !== "" ?
                    <div id={this.state.selected + '-' + this.props.type} className="subtabcontent active">
                        {(this.state.selected === 'update' || this.state.selected === 'delete') ?
                            this.state.items.length > 0 ?
                                this.props.type === 'fiches' ?
                                    <FicheSelector type={this.state.selected} fiches={this.state.items} queryFiche={this.queryItem} />
                                    :
                                    this.props.type === 'users' ?
                                        <UsersSelector type={this.state.selected} users={this.state.items} queryUser={this.queryItem} />
                                        :
                                        this.props.type === 'keywords' &&
                                        <KeywordsSelector type={this.state.selected} keywords={this.state.items} queryFiche={this.queryItem} />
                                :
                                <p>Aucun{this.props.type === 'fiches' && 'e'} {this.props.type} existant{this.props.type === 'fiches' && 'e'}</p>
                            :
                            <p>Création d'un{this.props.type === 'fiches' && 'e'} {this.props.type === 'fiches' ? 'nouvelle' : 'nouveau'} {this.props.type}</p>

                        }
                        {
                            this.props.type === 'fiches' ?
                                <FicheInput type={this.state.selected} fiche={this.state.cur_item} callback={this.getAllItems} />
                                :
                                this.props.type === 'users' ?
                                    <UsersInput type={this.state.selected} user={this.state.cur_item} callback={this.getAllItems} />
                                    :
                                    this.props.type === 'keywords' &&
                                    <KeywordsInput type={this.state.selected} keyword={this.state.cur_item} callback={this.getAllItems} />
                        }
                    </div>
                    :
                    this.props.type
                }
            </div>
        )
    }
}