import React, { Component } from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery';

class Oeuvre extends Component {
    default_column_oeuvre = {
        isSearched: false,
        width: '100%',
        paddingTop: '0',
        filter: "blur(5px)",
        zIndex: "1",
        margin: "0 auto -3em auto"
    }

    searched_column_oeuvre = {
        isSearched: false,
        width: '100%',
        paddingTop: '0',
        filter: "blur(5px)",
        zIndex: "1",
        margin: "0"
    }
    searched_column_oeuvre_selected = {
        isSearched: true,
        width: '100%',
        paddingTop: '0',
        filter: "none",
        zIndex: "1",
        margin: "0"
    }

    default_grille_oeuvre =
        {
            isSearched: true,
            width: '100%',
            paddingTop: '2rem',
            filter: "none",
            zIndex: "1",
            margin: "0"
        }
    searched_grille_oeuvre =
        {
            isSearched: false,
            width: '100%',
            paddingTop: '2rem',
            filter: "blur(5px)",
            zIndex: "1",
            margin: "0"
        }
    searched_grille_oeuvre_selected =
        {
            isSearched: true,
            width: '100%',
            paddingTop: '2rem',
            filter: "none",
            zIndex: "1",
            margin: "0"
        }

    constructor(props) {
        super(props);
        this.state = {}



    }
    componentDidUpdate(prevProps) {

        if (prevProps !== this.props) {
            this.updateOeuvre()
        }
    }
    componentDidMount = () => {
        this.updateOeuvre()

    }

    updateOeuvre = () => {
        if (this.props.isGrille) {

            if (this.props.isSearching) {
                if (this.props.isSearched) {
                    this.setState(this.searched_grille_oeuvre_selected)
                } else {
                    this.setState(this.searched_grille_oeuvre)
                }
            }
            else {
                this.setState(this.default_grille_oeuvre)
            }
        }
        else {
            if (this.props.isSearching) {

                if (this.props.isSearched) {
                    this.setState(this.searched_column_oeuvre_selected)
                }
                else {
                    this.setState(this.searched_column_oeuvre)
                }
            }
            else {
                let inverted = this.props.limits.size - this.props.index
                let blur = inverted - 1 + 3
                let width = this.props.limits.minwidth + this.props.index * 3
                let padding = this.props.limits.minpadding + (this.props.index / 10)
                let defaults = this.default_column_oeuvre
                defaults.width = width + '%'
                defaults.paddingTop = padding + "rem"
                defaults.filter = "blur(" + blur + "px)"
                defaults.zIndex = "1" + this.props.index
                this.setState(defaults)
            }
        }
    }
    static propTypes = {
        infos: PropTypes.object.isRequired,
    }

    showFiche(e) {
        if (this.props.isSearched || !this.props.isSearching) {
            this.props.setInfosFiche(this.props.infos, this.props.all_keywords)
        }
    }
    render() {
        return (
            <article onClick={(e) => this.showFiche(e)} className="item-oeuvre" style={{ width: this.state.width, paddingTop: this.state.paddingTop, filter: this.state.filter, zIndex: this.state.zIndex, margin: this.state.margin }}>
                <h1>
                    {this.props.infos.title}
                </h1>
            </article>
        );
    }

}

export default Oeuvre