import React, { Component } from 'react';

// import 'bootstrap/dist/css/bootstrap.min.css';

import { SearchBar, BottomBar, ListeOeuvres, Help, Fiche, Admin, TerminalWin, Modal } from '../components'
import { withCookies } from 'react-cookie';

import i18n from '../utils/i18n';

import apis from '../api/api'
// import { Modal } from 'react-bootstrap';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      searched_keyword: null,
      displayIsGrille: false,
      isDarkMode: true,
      barIsTop: false,
      infos_fiche: null,
      all_keywords: null,
      lang: i18n.language,
      modalContent: '',
    }
  }
  setSearchedKeyword = (value) => {
    this.setState({ searched_keyword: value })
  }

  changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    this.setState({ lang: i18n.language })

  }
  setDisplayGrille = (evt) => {
    this.setState({ displayIsGrille: !this.state.displayIsGrille, searched_value: '' })
  }
  setDisplayMode = () => {
    this.setState({ isDarkMode: !this.state.isDarkMode })
  }
  setInfosFiche = (infos) => {
    let fiche = <Fiche all_keywords={this.state.all_keywords} infos_fiche={infos} setSearchedValue={this.setSearchedValue} />
    this.setState({ modalContent: fiche })
  }
  setModalContent = (content) => {
    let help = <Help />
    let admin = <Admin cookies={this.props.cookies} />
    let terminal = <TerminalWin />

    let modal_content = ''

    if (content === 'help') {
      modal_content = help
    }
    else if (content === 'admin') {
      modal_content = admin
    }
    else if (content === 'terminal') {
      modal_content = terminal
    }

    this.setState({ modalContent: modal_content })
  }
  componentDidMount = async () => {
    await apis.getAllKeywordsWithKeys().then(items => {
      items = items.data.data
      this.setState({
        all_keywords: items,
      })
    })
  }
  render = () => {
    const { displayIsGrille, searched_keyword, lang, modalContent, isDarkMode } = this.state
    const displayClass = isDarkMode ? 'dark' : 'light'
    const searchIsTop = displayIsGrille || searched_keyword !== null
    return (
      <div className={"App " + displayClass}>
        <SearchBar searched_keyword={searched_keyword} isTop={searchIsTop} setSearchedKeyword={this.setSearchedKeyword} setModalContent={this.setModalContent} />
        <ListeOeuvres isGrille={displayIsGrille} searched_keyword={searched_keyword} setInfosFiche={this.setInfosFiche} />
        <BottomBar setDisplayMode={this.setDisplayMode} isDark={this.state.isDarkMode} setDisplayGrille={this.setDisplayGrille} isGrille={this.state.displayIsGrille} setModalContent={this.setModalContent} changeLanguage={this.changeLanguage} lang={lang} />
        <Modal content={modalContent} />
      </div>
    );
  }
}
export default withCookies(App);