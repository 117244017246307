import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import * as serviceWorker from "./serviceWorker";
import './style'
import { CookiesProvider } from 'react-cookie';


import './utils/i18n';




ReactDOM.render(
  <CookiesProvider>
    <App />
  </CookiesProvider>
  ,
  document.getElementById("root")
);

serviceWorker.unregister();