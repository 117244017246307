import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next';
import i18n from '../utils/i18n';

class FicheContent extends Component {
    static propTypes = {
        infos: PropTypes.object.isRequired,
    }
    handleKeywordClick = (e) =>
    {
        this.props.setSearchedValue(e.target.textContent)
        this.props.closeFiche()
    }
    render() {
        const { t, infos, all_keywords } = this.props

        if (typeof infos !== 'undefined') {
            return (
                <div className="fiche-contenu">

                    <header>

                        <h1 className="title">{infos.title} | {infos.year}</h1>
                        <h3 className="artist"> {infos.artist}</h3>
                        <a className="link" href={infos.url} target="_blank">{infos.url}</a>

                    </header>

                    <section className="fiche-keywords">
                        <h4>{t('fiche.keywords')} :</h4>
                        {
                            typeof all_keywords !== 'undefined' ?
                                <div className="keyword">{infos.keywords.map((item, i) => {
                                    return <span key={item} className='mots-cle' onClick={this.handleKeywordClick}>{i18n.language === 'en' ? all_keywords[item].title_en : all_keywords[item].title_fr}</span>
                                })}</div>
                                :
                                <h3>
                                    {t('site.loading')}
                                </h3>
                        }
                    </section>
                    <section className="fiche-texte">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: i18n.language === 'en' ? infos.body_en : infos.body_fr
                            }}></div>

                    </section>
                </div>
            );
        }
        else {
            return <h3>{t('fiche.none')}</h3>
        }
    }
}

export default withTranslation()(FicheContent)