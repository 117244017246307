import React, { Component } from 'react'
import Terminal from 'terminal-in-react';
import './terminal.css'

export default class TerminalWin extends Component {
    listContent = () => {
        return 'Hello world'
    }
    render() {

        return (
            <div id="modal-terminal" className="modal-content">
                <Terminal
                    color='green'
                    backgroundColor='black'
                    barColor='black'
                    style={{ fontWeight: "bold", fontSize: "1em" }}
                    commands={{
                        'ls': () => this.listContent(),
                        popup: () => alert('Terminal in React')
                    }}
                    descriptions={{
                        'ls': 'list all content',
                        alert: 'alert', popup: 'alert'
                    }}
                    msg='Welcome in the Re|Search Terminal. You have found the easter egg.'
                />
            </div>

        );
    }
}