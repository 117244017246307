import React, { Component } from 'react'

import $ from 'jquery';

import { withTranslation } from 'react-i18next';

class SearchError extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searched_value: ''
        }
    }

    hideError = (callback = null) => {
        $('.search-error').slideUp('slow', callback);
    }
    showError = (callback = null) => {
        callback()
        $('.search-error').slideToggle('slow');
    }
    componentDidMount = () => {
        $('.search-error').hide()
        if (this.props.show) {
            this.showError()
        }
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps !== this.props) {
            const { show, searched_value } = this.props

            const f = () => this.setState({ searched_value: searched_value })
            if (prevProps.show !== show) {
                show ? this.showError(f) : this.hideError(f)
            }
            

        }

    }

    render() {
        const { searched_value } = this.state
        return (

            <div className='search-error' onClick={this.hideError} >
                <p> Votre recherche de <em>{searched_value}</em> ne correspond à aucun mots-clés.</p>
                <p >Suggestions :</p>
                <ul >
                    <li>Vérifiez l’orthographe des termes de recherche.</li>
                    <li>Essayez d'autres mots.</li>
                    <li>Utilisez des mots clés plus généraux.</li>
                    <li>Spécifiez un moins grand nombre de mots.</li>
                </ul>

            </div >

        );
    }
}

export default withTranslation()(SearchError);