import React, { Component } from 'react'

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import $ from 'jquery';

import apis from '../api/api'
import Typed from 'react-typed';

import { withTranslation } from 'react-i18next';
import SearchError from './SearchError'
class SearchBar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searched_value: '',
            value: '',
            infos_keyword: null,
            error: false,
        }
    }
    handleSubmit(event) {

        event.preventDefault();
        let value = $('#formulaire_input').val().toLowerCase()
        this.keywordExist(value)
    }
    hideError = (e) => {
        $('.search-error').slideUp('slow');
    }

    keywordExist = (keyword) => {
        console.log(keyword)
        console.log(keyword !== "")
        if (keyword !== "") {
            this.setState({ searched_value: keyword }, async () => {
                await apis.getKeywordByTitle(keyword)
                    .then(async (result) => {
                        if (result.status === 200) {
                            this.setState({ error: false })
                            let keyword = result.data.data
                            this.setState({ infos_keyword: keyword })
                            this.props.setSearchedKeyword(keyword)
                        }
                        else {
                            this.props.setSearchedKeyword(null)
                            if (keyword === 'admin') {
                                this.props.setModalContent('admin')
                            }
                            else if (keyword === 'knowlton') {
                                this.props.setModalContent('terminal')
                            }
                            else {
                                this.setState({ error: true })
                            }
                        }
                    })
                    .catch((e) => {
                        this.props.setSearchedKeyword(null)
                        if (keyword === 'admin') {
                            this.props.setModalContent('admin')
                        }
                        else if (keyword === 'knowlton') {
                            this.props.setModalContent('terminal')
                        }
                        else {
                            this.setState({ error: true })
                        }
                    })
            })
        }
        else {
            this.props.setSearchedKeyword(null)
            this.setState({ error: false })
        }
    }
    componentDidUpdate = (prevProps) => {
        if (prevProps.searched_value !== this.props.searched_value) {
            this.setState({ searched_value: this.props.searched_value })
        }
    }
    handleChange = (event) => {
        if (event.target) {
            this.setState({ [event.target.name]: event.target.value });
        }
    }
    render() {
        const { t } = this.props;
        const { searched_value, value } = this.state
        return (
            <section id="form-nav" className={this.props.isTop ? "search-top" : 'search-bar'}>
                <form onSubmit={(event) => this.handleSubmit(event)} id="formulaire" method="post">

                    <Typed

                        strings={t('searchbar.placeholder', { returnObjects: true })}
                        typeSpeed={100}
                        backSpeed={50}
                        attr='placeholder'
                        bindInputFocusEvents={true}
                        loop={true}
                    >
                        <input type="text" name="value" id="formulaire_input" className="typed" value={value} onChange={this.handleChange} />
                    </Typed>
                    <button className='search-btn' ><FontAwesomeIcon icon={faSearch} /></button>
                </form>
                {
                    searched_value !== '' &&
                    <SearchError show={this.state.error} searched_value={searched_value} />
                }
            </section>
        );
    }
}

export default withTranslation()(SearchBar);