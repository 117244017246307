import axios from 'axios'
import errorlogger from '../utils/errorlogger'
const user = axios.create({
    baseURL: 'http://doutreguay.com:3010/user',
})

export const signupUser = payload => {
    user.post(`/signup`, payload)
        .catch((error) => {
            console.log(errorlogger(error))
        });

}
export const loginUser = payload => user
    .post(
        `/login`,
        payload,
        {
            validateStatus: function (status) {
                return status >= 200 && status < 500;
            }
        })
    .catch((error) => {
        errorlogger(error)
    });

export const infoUser = payload => {

    return user
        .get(`/me`, { params: {}, headers: payload })
        .then((result) => {
            return result.data
        })
        .catch((error) => {
            console.log(errorlogger(error))
            return errorlogger(error)
        });

}
export const getAllUsers = payload => {

    return user
        .get(`/all`, { params: {}, headers: payload })
        .then((result) => {
            return result.data
        })
        .catch((error) => {
            console.log(errorlogger(error))
        });

}

const users = {
    signupUser,
    infoUser,
    loginUser,
    getAllUsers,
}
export default users