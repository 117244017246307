import React, { Component } from 'react'

import { withTranslation } from "react-i18next";

import FicheContent from './FicheContent'

class Fiche extends Component {
    render() {
        const { t, infos_fiche, all_keywords, setSearchedValue } = this.props
        return (
            <div id="modal-fiche" className="modal-content">
                {infos_fiche !== null ?
                    <FicheContent setSearchedValue={setSearchedValue} all_keywords={all_keywords} infos={infos_fiche} closeFiche={this.closeFiche} />
                    :
                    <h3>{t('fiche.none')}</h3>
                }

            </div>
        );
    }
}
export default withTranslation()(Fiche)