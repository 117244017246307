import axios from 'axios'
import i18n from '../utils/i18n';
const api = axios.create({
    // baseURL: 'http://158.69.26.2:65535/api',
    baseURL: 'http://doutreguay.com:3010/api',
})

export const insertFiche = payload => api.post(`/fiche`, payload)
export const getAllFiches = () => api.get(`/fiches`)
export const updateFicheById = (id, payload) => api.put(`/fiche/${id}`, payload)
export const deleteFicheById = id => api.delete(`/fiche/${id}`)
export const getFicheById = id => api.get(`/fiche/${id}`)
// export const getFichesByKey = idkeyword => api.get(`/fiches/${idkeyword}`)
export const getFichesByKey = idkeyword => api.get(`/fiches/keyword/${idkeyword}`)

export const insertKeyword = payload => api.post(`/keyword`, payload)
export const getAllKeywords = () => api.get(`/keywords`)
export const getAllKeywordsWithKeys = () => api.get(`/keywords/true`)
export const updateKeywordById = (id, payload) => api.put(`/keyword/${id}`, payload)
export const deleteKeywordById = id => api.delete(`/keyword/${id}`)
export const getKeywordById = id => api.get(`/keyword/${id}`)
export const getKeywordByTitle = title => api.get(`/keyword/title/${title}/field/title_${i18n.language}`)
// export const getKeywordByTitle = title => api.get(`/keyword/title/${title}/field/title_${i18n.language}`, {
//     validateStatus: function (status) {
//         return status >= 200 && status < 500;
//     }
// })


const apis = {
    insertFiche,
    getAllFiches,
    updateFicheById,
    deleteFicheById,
    getFicheById,
    getFichesByKey,
    insertKeyword,
    getAllKeywords,
    getAllKeywordsWithKeys,
    updateKeywordById,
    deleteKeywordById,
    getKeywordById,
    getKeywordByTitle
}


export default apis